import { EventsService } from './../services/events.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';

import { Storage } from '@ionic/storage-angular';
import { inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';



export const CurrentEventSessionsResolver: ResolveFn<any> = async (

  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  storage: Storage = inject(Storage),
  eventsService: EventsService = inject(EventsService)
): Promise<Observable<{}>> =>{ 

  const selectedCustonerID = await storage.get('aecuid').then((val) => {return val});
  const selectedEventID = await storage.get('aeeventid').then((val) => {return val});
  const selectesSessionKey = await storage.get('selectedSessionKey').then((val) => {return val});
  
console.log('selectedCustonerID', selectesSessionKey);

  if (selectesSessionKey && selectesSessionKey != '') {
  return eventsService.getProgramSessions(selectedCustonerID, selectedEventID, selectesSessionKey).then((sessions) => {    
    console.log('event', sessions);    
    return sessions});
  }
    else {
        return of({}); 
        }

}





/*
@Injectable()
export class ParticipantsResolver implements Resolve<any> {

  constructor(
    private eventsService: EventsService, 
    private storage: Storage) {
     }

  async resolve() {
    const selectedCustonerID = await this.storage.get('aecuid').then((val) => {return val});
    const selectedEventID = await this.storage.get('aeeventid').then((val) => {return val});
    return this.eventsService.getEventV2(selectedCustonerID, selectedEventID).then((event) => {return event});
  }
}*/