import { AvatarService } from './services/avatar.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { App } from '@capacitor/app';
import { Auth, User } from '@angular/fire/auth';

import { Appsettings } from './interfaces/appsettings';
import { GlobalSignalService, GLOBAL_SIGNAL_SERVICE  } from './services/signalservice.service';
import { Storage } from '@ionic/storage-angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  currentSettings: any = null;
  selectedCustomerID = '';
  selectedEventID = '';
  eventlogo: any = null;
  dark = false;


  activePageTitle = 'Hjem';
  activeIndex = 0;
  Pages = [
    {
      title: 'Hjem',
      url: '',
      icon: 'home'
    },   
    {
      title: 'Innsjekk',
      url: '/barcode-scanning',
      icon: 'checkmark-done-circle'
    },   
    {
      title: 'Påmeldte deltakere',
      url: '/participants',
      icon: 'people-circle'
    },       
    {
      title: 'Innsjekkede deltakere',
      url: '/checkedinparticipants',
      icon: 'people-circle'
    },       
    {
      title: 'Alle registrerte personer',
      url: '/allparticipants',
      icon: 'people-circle'
    }

  ];

  constructor(
    private navController: NavController,
    private zone: NgZone,
    private auth: Auth,
    private avatarService: AvatarService,
    private storage: Storage,
    private globalSignalService: GlobalSignalService

  ) {
    this.storage.create();
    this.currentSettings = this.globalSignalService.getSignal<Appsettings>('currentSettings');    
    
    const userProfile = this.avatarService.getUserProfile();
    if (userProfile !== null) {
      userProfile.appUser.subscribe((data) => {
        console.log('userProfile', data)        
      });
      console.log(userProfile.authuser);
    }
  }

  async ngOnInit() {
    console.log('Initializing App');
    const currentUser: User | null = await this.auth.currentUser;
    App.addListener('appUrlOpen', (data: { url: string }) => this.zone.run(() => this.handleDeeplink(data.url)));
await this.aeinit();
  }

  private handleDeeplink(link: string) {
    console.log('Got deeplink: ', link)
    const path = this.getPath(link);
    if (path) {
      this.navController.navigateRoot(['/', path]);
    }
  }

  private getPath(link: string): string {
    console.log('getPath: ', link)
    const url = new URL(link);
    const paths = url.pathname.split('/');
    return paths[paths.length - 1] || (url.protocol === 'aecheckin:' ? url.hostname : '');
  }

  async aeinit() {
    console.log("aeinit");
    //console.log(this.form.value.selectedEvent);
    const aecuid = await this.storage.get('aecuid').then((val) => {
      if (val && val.length > 0) {        
        return val;
       }
        else {
          return null;
        }
    });
    const aeventid = await this.storage.get('aeeventid').then((val) => {
      if (val && val.length > 0) {
        return val;
       }
        else {
          return null;
        }
    });
   // this.titleService.setTitle(this.eventname); 
    const aeventname = await this.storage.get('aeeventname').then((val) => {
      if (val && val.length > 0) {
        return val;
       }
        else {
          return null;
        }
    });
    const aeeventlogo = await this.storage.get('aeeventlogo').then((val) => {
      if (val && val.length > 0) {
        return val;
       }
        else {
          return null;
        }
    });
    const appTenantID = await this.storage.get('apptenantID').then((val) => {
      if (val && val.length > 0) {
        return val;
       }
        else {
          return null;
        }
    });

const Appsettings = {
aecuid: aecuid,
aeeventid: aeventid,
aeeventname: aeventname,
aeeventlogo: aeeventlogo,
appTenantID: appTenantID
}

console.log('Appsettings i appcomponent',Appsettings);

return this.globalSignalService.setSignal('currentSettings', Appsettings);


  }

}
