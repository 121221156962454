import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  canActivate,
} from '@angular/fire/auth-guard';
import { AuthGuard } from './guards/auth.guard';
import { EventReadyGuard } from './guards/appready.guard';
import { ParticipantsResolver } from './resolvers/participants.resolver';
import { NoAuthGuard } from './guards/no-auth/no-auth.guard';
import { CurrentEventResolver } from './resolvers/currentevent.resolver';
import { CurrentEventSessionsResolver } from './resolvers/eventsessions.resolver';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);

const routes: Routes = [
  
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  /*{
    path: '',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
    ...canActivate(redirectLoggedInToHome),
  },*/
  /*{
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    //...canActivate(redirectLoggedInToHome),
  },*/
  {
    path: 'home',
    canActivate: [AuthGuard],
    resolve: {
      currentEvent: CurrentEventResolver
    },
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
    //...canActivate(redirectUnauthorizedToLogin),
  },
  /*{
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard],
  },  */
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canActivate: [NoAuthGuard],
  },  
  {
    path: 'smslogin',
    loadChildren: () => import('./SMSlogin/signin-up.module').then(m => m.SMSSignUpPageModule),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'smscode',
    loadChildren: () => import('./sms-kode/sms-kode.module').then(m => m.SmsKodePageModule),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'selectevent',
    //canActivate: [AuthGuard],
    loadChildren: () =>
      import('./selectevent/selectevent.module').then((m) => m.SelecteventPageModule)    
  }, 
  {
    path: 'appinit',
    //canActivate: [AuthGuard],
    loadChildren: () =>
      import('./appinit/appinit.module').then((m) => m.AppinitPageModule)    
  },    
  {
    path: 'appinit/:id',
    //canActivate: [AuthGuard],
    loadChildren: () =>
    import('./appinit/appinit.module').then((m) => m.AppinitPageModule)     
  },  
  {
    path: 'appinit/:id/:pid',
    //canActivate: [AuthGuard],
    loadChildren: () =>
    import('./appinit/appinit.module').then((m) => m.AppinitPageModule)      
  },   
  {
    path: 'participants',
    canActivate: [AuthGuard],
    resolve: {
      currentEvent: CurrentEventResolver
    },
    loadChildren: () =>
      import('./participants/participants.module').then((m) => m.ParticipantsPageModule)
  },
  {
    path: 'allparticipants',
    canActivate: [AuthGuard],
    resolve: {
      currentEvent: CurrentEventResolver
    },
    loadChildren: () =>
      import('./allparticipants/allparticipants.module').then((m) => m.AllParticipantsPageModule)
  },  
  {
    path: 'checkedinparticipants',
    canActivate: [AuthGuard],
    resolve: {
      currentEvent: CurrentEventResolver
    },    
    loadChildren: () => import('./checkedinparticipants/checkedinparticipants.module').then( m => m.CheckedinParticipantsPageModule)
  },

  {
    path: 'barcode-scanning',
    canActivate: [AuthGuard],
    resolve: {
      currentEvent: CurrentEventResolver,
      currentSessions: CurrentEventSessionsResolver
    },
    loadChildren: () =>
      import('./barcode-scanning/barcode-scanning.module').then(
        (m) => m.BarcodeScanningModule
      ),
  },
  {
    path: 'scanqr',
    canActivate: [AuthGuard],
    loadChildren: () => import('./scanqr/scanqr.module').then( m => m.ScanqrPageModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    resolve: {
      currentEvent: CurrentEventResolver
    },  
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
