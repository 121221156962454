import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
//import { FirebaseAuthenticationService } from '@app/core';
import { Auth, User } from '@angular/fire/auth';
@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  constructor(
   // private readonly firebaseAuthenticationService: FirebaseAuthenticationService,
    private readonly router: Router,
    private auth: Auth,
  ) {}

  public async canActivate(): Promise<boolean> {
    //const user = await this.firebaseAuthenticationService.getCurrentUser();
    const currentUser: User | null = await this.auth.currentUser;
    if (currentUser && currentUser.emailVerified == true) {

console.log("user er logget inn, skal sendes til forsiden??", currentUser)
      //this.router.navigate(['/']);


      return false;
    }
    console.log("user er ikke logget inn, kan gå til login??")
    return true;
  }
}
