import { inject, Injectable, InjectionToken, signal, WritableSignal } from '@angular/core';
import { Appsettings } from '../interfaces/appsettings';

@Injectable({
  providedIn: 'root'
})
export class GlobalSignalService {

  signalMap = new Map<string, WritableSignal<unknown>>();

  constructor() { }

  getSignal<T>(key: string): WritableSignal<T> {
    if (!this.signalMap.has(key)) {
      this.signalMap.set(key, signal<T | undefined>(undefined));
    }
    console.log("getSignal key", key)
    console.log("signalmap", this.signalMap);
    return this.signalMap.get(key) as WritableSignal<T>;

    

  }

  setSignal<T>(key: string, value: T): void {
    this.getSignal<T>(key).set(value);
    console.log("setsignal key", key, "value", value)
    console.log("signalmap", this.signalMap);
  }


  async updateCurrentSettings(settingname, settingvalue) {
    console.log('updateCurrentSettings', settingname, settingvalue)
    const currentSettings = this.getSignal<Appsettings>('currentSettings'); 
    const newsettings = currentSettings();            
    newsettings[settingname] = settingvalue; 
    return this.setSignal('currentSettings', newsettings);
}


}

export let GLOBAL_SIGNAL_SERVICE = new InjectionToken<GlobalSignalService>('GLOBAL_SIGNAL_SERVICE', {
  providedIn: 'root',
  factory: () => inject(GlobalSignalService)
});