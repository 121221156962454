import { Injectable } from '@angular/core';
//import { Auth } from '@angular/fire/auth';

import {
  Auth,
  User,
  updateProfile 
} from '@angular/fire/auth';

import { doc, docData, Firestore, getFirestore, setDoc } from '@angular/fire/firestore';
import {
  getDownloadURL,
  ref,
  Storage,
  uploadString,  
} from '@angular/fire/storage';
import { Photo } from '@capacitor/camera';



@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  constructor(
    private auth: Auth,
    private firestore: Firestore,
    private storage: Storage
  ) {}

  getUserProfile() {
    console.log('this.auth.currentUser', this.auth.currentUser)
    const db = getFirestore();
    const user = this.auth.currentUser || null;

    if (!user) {
      return null;
    }

    const userDocRef = doc(db, `appusers/${user.uid}`);
    //const userDocRef = doc(db, "users", "${user.uid}");
    //console.log('userDocRef', userDocRef)
    return {appUser:docData(userDocRef), authuser:user};
  }

  async uploadImage(cameraFile: Photo) {
    const user = this.auth.currentUser;

    if (!user) {
      return null;
    }

    const path = `uploads/${user.uid}/profile.png`;
    const storageRef = ref(this.storage, path);

    try {
      await uploadString(storageRef, cameraFile.base64String || '', 'base64');

      const imageUrl = await getDownloadURL(storageRef);
      const photoUrl = imageUrl;
      const avatar = photoUrl;

      const userDocRef = doc(this.firestore, `appusers/${user.uid}`);
      await setDoc(userDocRef, {
        imageUrl,
        photoUrl,
        avatar
      }, { merge: true });           
      return true;

    } catch (e) {
      return null;
    }
  }
  async updateUserImage(imageUrl: string, displayName: string) {
    console.log('updateUserImage', imageUrl)
    const user = this.auth.currentUser;

    try {

await updateProfile(user, {
        photoURL: imageUrl,
        displayName: displayName
})
/*
      await (user as any).updateProfile({
        photoURL: imageUrl
      });
*/
      return true;
    } catch (e) {
      console.log('e', e)
      return null;
    }
  }


}

