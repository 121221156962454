// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: 'AIzaSyDTrcKa4Z_Q_1poUrAULjw49hWCNdW19Vw',
    authDomain: 'appevent-7e011.firebaseapp.com',
    databaseURL: 'https://appevent-7e011.firebaseio.com',
    projectId: 'appevent-7e011',
    storageBucket: 'appevent-7e011.appspot.com',
    messagingSenderId: '340931202522',
    appId: '1:340931202522:web:ea0201fad8b6648703c4e3'
  },
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
