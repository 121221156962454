<ion-app [class.dark-theme]="dark">
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content">
      <ion-content>
        <ion-list id="menu-list" lines="none">
          <img *ngIf="currentSettings()?.aeeventlogo" class="spimage" src="{{currentSettings()?.aeeventlogo}}" alt="Event logo">
          <ion-list-header>Meny</ion-list-header>
          <!--<ion-note>johndoe@gmail.com</ion-note>-->
          
          <ion-menu-toggle auto-hide="false" *ngFor="let pages of Pages; let i = index">
            <ion-item (click)="activeIndex = i" routerDirection="root" [routerLink]="[pages.url]" lines="none" detail="false" [attr.color]="activeIndex == i ? 'primary' : ''">
              <ion-icon slot="start" [ios]="pages.icon + '-outline'" [md]="pages.icon + '-sharp'"></ion-icon>
              <ion-label>{{ pages.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>

        </ion-list>

        <ion-list lines="none">
          <!--<ion-list-header>
            Tutorial
          </ion-list-header>
        -->
          <ion-menu-toggle autoHide="false">

            <ion-item routerDirection="root" [routerLink]="'/profile'" lines="none" detail="false" >
              <ion-icon slot="start" [ios]="'person-circle-outline'" [md]="'person-circle-sharp'"></ion-icon>
              <ion-label>Min Side</ion-label>
            </ion-item>

            <ion-item routerDirection="root" [routerLink]="'/selectevent'" lines="none" detail="false" >
              <ion-icon slot="start" [ios]="'people-circle-outline'" [md]="'people-circle-sharp'"></ion-icon>
              <ion-label>Velg arrangement</ion-label>
            </ion-item>

            <ion-item>
              <ion-icon slot="start" name="moon-outline"></ion-icon>
              <ion-toggle [(ngModel)]="dark">
                Mørkt modus
              </ion-toggle>
            </ion-item>

          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>