import { Injectable } from '@angular/core';
//import { collection, getFirestore, query, where, getDocs } from "firebase/firestore";
import { doc, collection, query, where, getDoc, getDocs, docData, Firestore, getFirestore, setDoc, addDoc, arrayUnion  } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { get } from 'http';
import { DeltakerModel } from '../interfaces/deltakermodel';


@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(
    private storage: Storage
  ) {}


    async getEvents(customeruid: string, filter?: string) {
      const db = getFirestore();
     

      const eventsCol = collection(db, `appeventcustomers/${customeruid}/events`);
      const eventsQuery = query(eventsCol, 
        //where( "id", "==", "7ce9e397"),
        //where("aktiv", "==", true),
        where("eventFunctions.EventStoreApp", "==", true)
        );
      const eventsSnapshot = await getDocs(eventsQuery);
      const eventsList = eventsSnapshot.docs.map(doc => doc.data());

console.log('eventsList', eventsList)

      return eventsList;
    }

    async getAppActiveEvents(customeruid: string, filter?: string) {
      const db = getFirestore();
     

      const eventsCol = collection(db, `appeventcustomers/${customeruid}/events`);
      const eventsQuery = query(eventsCol, 
        //where( "id", "==", "7ce9e397"),
        //where("aktiv", "==", true),
        where("eventFunctions.EventStoreApp", "==", true)
        );
      const eventsSnapshot = await getDocs(eventsQuery);
      const eventsList = eventsSnapshot.docs.map(doc => doc.data());

console.log('eventsList', eventsList)

      return eventsList;
    }

async getEvent(customeruid: string, eventuid: string) {
  console.log('getEvent', customeruid, eventuid)
      const db = getFirestore();
      const eventDocRef = doc(db, `appeventcustomers/${customeruid}/events`, eventuid);
      const eventDoc = await docData(eventDocRef).subscribe(doc => {
        console.log('Document data:', doc);
        return doc;
      });

      if (eventDoc) {
        return eventDoc;
      }
      else {
        return null;
      }
}

async getParticipant(customeruid: string, eventuid: string, participantid: string): Promise<any> {
  console.log('getparticipant', customeruid, eventuid, participantid)
      const db = getFirestore();
      const eventDocRef = doc(db, `appeventcustomers/${customeruid}/events/${eventuid}/eventregistrationtest`, participantid);

      return new Promise(async (resolve, reject) => {

        return await docData(eventDocRef).subscribe(doc => {
          console.log('Document data deltaker:', doc);
  
          if (doc) {
            resolve(doc);
          }
          else {
            reject('Deltaker ikke funnet');
          }
  
        }, reject);
  
   
      });


}

async checkinParticipant(customeruid: string, eventuid: string, participantid: string, checkinstatus: boolean, participantstatus: string, currentUser:any, checkintype: string, checkinlocation:string, userData:DeltakerModel): Promise<any> {

  const db = getFirestore();

console.log('checkinParticipant', customeruid, eventuid, participantid, checkinstatus, participantstatus, currentUser, checkintype, checkinlocation, userData);


  try {
    const userDocRef = doc(db, `appeventcustomers/${customeruid}/events/${eventuid}/eventregistrationtest/${participantid}`);
    const checkinTableRef = doc(db, `appeventcustomers/${customeruid}/events/${eventuid}/deltakerinnsjekkinger/${participantid}_${checkinlocation}`);

    //const userref = db.collection('appeventcustomers').doc(appeventCustomerID).collection('events').doc(eventID).collection('deltakerinnsjekkinger').doc(eventkey).collection(selectedsession).doc(data.id).collection('extra_checkins');

    return await setDoc(userDocRef, {appCheckin: arrayUnion({checkinvalid: checkinstatus,  participantstatus: participantstatus, checkintime: new Date(), checkedinby: currentUser, checkintype:checkintype, checkinlocation: checkinlocation })}, { merge: true }).then(async () => {
      console.log('Document successfully written!');
  
      return await getDoc(checkinTableRef).then(async (doc) => {

        if (doc.exists()) {
        
          console.log("Document data:", doc.data());
          userData.antallInnsjekkinger = doc.data().antallInnsjekkinger?doc.data().antallInnsjekkinger + 1:1;      
              
          return await setDoc(checkinTableRef, userData, { merge: true }).then(() => {
  
            return true;
            
            }).catch((error) => {
              console.error("Error adding document: ", error);
              return null;
            });
        
        
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          
          return await setDoc(checkinTableRef, userData, { merge: true }).then(() => {
  
            return true;
            
            }).catch((error) => {
              console.error("Error adding document: ", error);
              return null;
            });

        }
        
        })



  
  
     
    });







       
   
  } catch (e) {
    console.error("Error adding document: ", e);
    return null;
  } 
}


async getEventV2(customeruid: string, eventuid: string): Promise<any> {
 // console.log('getEvent', customeruid, eventuid)
      const db = getFirestore();
      const eventDocRef = doc(db, `appeventcustomers/${customeruid}/events`, eventuid);

      return new Promise(async (resolve, reject) => {

      return await docData(eventDocRef).subscribe(doc => {
      // console.log('Document data:', doc);

        if (doc) {
          resolve(doc);
        }

      }, reject);

 
    });
}




async getProgramSessions(customeruid: string, eventuid: string, sessionKey:string): Promise<any> {
  const db = getFirestore();
  const sessionsCol = collection(db, `appeventcustomers/${customeruid}/events/${eventuid}/programinstanser/${sessionKey}/sesjoner`);
  const eventsQuery = query(sessionsCol, where("aktiv", "==", true));
  const eventsSnapshot = await getDocs(eventsQuery);
  const sessionslist = eventsSnapshot.docs.map(doc => doc.data());

console.log('sessionslist', sessionslist)

  return sessionslist;
}


    async getCustomers() {
      const db = getFirestore();
      const eventsCol = collection(db, "appeventcustomers");
      const eventsQuery = query(eventsCol, where("active", "==", true));
      const eventsSnapshot = await getDocs(eventsQuery);
      const customerslist = eventsSnapshot.docs.map(doc => doc.data());

console.log('customerlist', customerslist)

      return customerslist;
    }

    async getParticipants(customeruid: string, eventuid: string) {
      const db = getFirestore();
      const eventsCol = collection(db, `appeventcustomers/${customeruid}/events/${eventuid}/normalizedregistrations`);
      const eventsQuery = query(eventsCol, where("active", "==", true));
      const eventsSnapshot = await getDocs(eventsQuery);
      const customerslist = eventsSnapshot.docs.map(doc => doc.data());

console.log('customerlist', customerslist)

      return customerslist;
    }


    async getCurrentAppEventLogo() {
      return await this.storage.get('aeeventlogo');
    }

    async getAppEventUser(customeruid: string, eventid: string, deltakerkode: string) {
      const db = getFirestore();
     // return this.getsettings().then(async (settings) => {
      //  console.log('settings', settings);
        const participantsRef = collection(db, `appeventcustomers/${customeruid}/events/${eventid}/normalizedregistrations`);
        const q = query(participantsRef, where('id', '==', deltakerkode)
        //sjekk på om deltaker er registrert osv?
          //, where('participanttype.name', '!=', 'Utstiller')
        )
  
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          console.log('Ingen deltaker funnet med uid: ' + deltakerkode, 'customerref: ' + `appeventcustomers/${customeruid}/events/${eventid}/normalizedregistrations`);
          return { status: "fail", response: "Deltaker ikke funnet" };
        }
        const deltaker = querySnapshot.docs[0].data();
        console.log('Person funnet - returnerer deltaker', deltaker.id)
        return deltaker;
     // });
    }


    async getUserByMobile(customeruid: string, eventid: string, mobilenumber: string, countrycode: string, smskode: string, fromname: string, testmodus: boolean) {
      console.log("Getuser mobile: " + mobilenumber);
  

        const db = getFirestore();
        const participantsRef = collection(db, `appeventcustomers/${customeruid}/events/${eventid}/normalizedregistrations`);
        let numberwithoutspaces = mobilenumber.replace(/\s/g, '');
        let numberwithoutspacesString = numberwithoutspaces.toString();
        let numberasnumber = Number(numberwithoutspaces);
        let numberandcountry = countrycode + mobilenumber;
  
  console.log("numberwithoutspaces: " + numberwithoutspaces);
  
        const q = query(participantsRef, where('Personinformasjon.mobil', 'in', [mobilenumber, numberwithoutspaces, numberwithoutspacesString, numberasnumber, numberandcountry])
          //, where('participanttype.name', '!=', 'Utstiller')
        )
  
        const querySnapshot = await getDocs(q);
        console.log("querySnapshot: " + querySnapshot.size);
  
  
        if (querySnapshot.empty) {
          console.log('Ingen deltaker funnet med mobil: ' + mobilenumber, 'customerref: ' + `appeventcustomers/${customeruid}/events/${eventid}/normalizedregistrations`);
          return { status: "fail", response: "Deltaker ikke funnet" };
        }
        if (querySnapshot.size > 1) {
          console.log('Flere deltakere funnet med mobil: ' + mobilenumber, 'customerref: ' + `appeventcustomers/${customeruid}/events/${eventid}/normalizedregistrations`);
          console.log(querySnapshot.docs[0].data())
  
          return { status: "fail", response: "Flere deltakere funnet med mobil" };
        }
  
        const deltakerID = querySnapshot.docs[0].data().id;
  
        if (!testmodus) {
          return this.sendSMSKode(countrycode + mobilenumber, smskode, fromname).then((result) => {
            console.log('Person funnet - SMS sendt');
            return result;
          }).then(() => {
            console.log('Person funnet - SMS sendt, skal returnere id', deltakerID)
            return { status: "ok", response: deltakerID };
          });
        }
        else {
          console.log('Person funnet - SMS ikkse sendt pga debugmode, skal returnere id', deltakerID)
          return { status: "ok", response: deltakerID };
        }
  
   
    }
  
    async updateAndGetAppEventUser(customeruid: string, eventid: string, appEventUID: string, useruid: string): Promise<any> {
     // return this.getsettings().then((values) => {
       // console.log("updateAndGetAppEventUser", appEventUID, useruid)

       const db = getFirestore();
    
       try {
         const userDocRef = doc(db, `appeventcustomers/${customeruid}/events/${eventid}/normalizedregistrations/${appEventUID}`);
         await setDoc(userDocRef, {
          uID: useruid,
          appuseractive: true,
          appuserUpdated: new Date()
        }, { merge: true }).then(() => {
          console.log("userinfoRef - Oppdatert med id / uid", appEventUID, useruid);
          
          return getDoc(userDocRef).then((doc) => {
            console.log("userinfoRef - hentet på nytt etter oppdatering");
            return doc.data();
          });
           //return true;
           /*
           return userinfoRef.valueChanges().subscribe((response) => {
            console.log("userinfoRef - Oppdatert med id / uid", appEventUID, useruid);
           // this.currentAppEventUserSubject.next(response);
            return response;
          });
          */
         });           
        
       } catch (error) {
         console.error("Error adding document: ", error);
         
         return new Error('userinfoRef - Oppdatering feilet ' + error);
       }

    }


    async sendSMSKode(mobilenumber: string, smskode: string, fromname: string) {

      const db = getFirestore();
    
      try {
        //const userDocRef = doc(db, `SMSQueueGW`);
        const docRef = await addDoc(collection(db, `SMSQueueGW`), {
          recipientsms: mobilenumber,
          message: smskode,
          sendername: fromname,
          created: new Date()
        });
        //.then(() => {
          console.log('SMS sendt fra funksjonen', mobilenumber, smskode, fromname, docRef.id);
         // return true;
       // });           
       
      } catch (e) {
        console.error("Error adding document: ", e);
        return null;
      }
    }



  }
