import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {
  Auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  User,
  updateProfile,
  authState,
  onAuthStateChanged 
} from '@angular/fire/auth';

//import firebase from 'firebase/compat/app';
//import { getAuth, onAuthStateChanged } from "firebase/auth";
import { GlobalSignalService } from './signalservice.service';
import { Appsettings } from '../interfaces/appsettings';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //fbauth = getAuth();
  currentSettings: any = null;
  constructor(
    private auth: Auth,
    private storage: Storage,
    private globalSignalService: GlobalSignalService
  ) {

    this.currentSettings = this.globalSignalService.getSignal<Appsettings>('currentSettings');

    this.auth.tenantId = this.currentSettings()?.appTenantID;

    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        console.log('onAuthStateChanged, user uid: ', user.uid, ' Displayname: ' , user.displayName);
        user.getIdTokenResult().then((idTokenResult) => {
          console.log('idTokenResult', idTokenResult);
          if (idTokenResult.claims['ownercompanyId']) {
            this.storage.set('aecuid', idTokenResult.claims['ownercompanyId']);          
          }

          if (idTokenResult.claims['iseventadmin'] && idTokenResult.claims['iseventadmin'] == true) {
            this.storage.set('aeEventAdmin', true);
           this.globalSignalService.updateCurrentSettings('aeusertypeAdmin', true);
          }
          else {
            this.storage.set('aeEventAdmin', false);
          }
        });
      } else {
        console.log('onAuthStateChanged user not logged in');
        // User is signed out?
      }
    });
  }
  
  getUser(): Observable<User> {
    return authState(this.auth);
  }

  async getCurrentUser() {
    return this.auth.currentUser;
  }

  async register({ email, password }: { email: string, password: string }) {
    try {
      const user = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password,

      );
      return user;
    } catch (e) {
      return null;
    }
  }

  async checkAndLoginUser(email: string, password: string, tenantId: string) {

    // return this.getCurrentSettings().then((result) => {
 
this.auth.tenantId = tenantId;

    try {
      const user = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password

      );
      return user;
    } catch (error) {

    //  return null;

      {      
        if (error.toString().indexOf('auth/email-already-in-use')) {   
          
return this.login({ email, password }).then((result) => {
            return result;
});

          try {
            const user = await signInWithEmailAndPassword(this.auth, email, password);
            return user;
          } catch (e: any) {
            console.log('login error', e.message)
            return e.message;
          }

   
        }
        else {
         console.log("createUserWithEmailAndPassword", error);
         return error;
        }
       }

    }

   }


async updateProfile(displayName: string, photoURL: string) {

const user = this.auth.currentUser;

updateProfile(user, {
  displayName: displayName,
  photoURL: photoURL
})

}

async login({ email, password }: { email: string, password: string }) {

  this.auth.tenantId = null;

  try {
    const user = await signInWithEmailAndPassword(this.auth, email, password);
    return user;
  } catch (e: any) {
    console.log('login error', e.message)
    return e.message;
  }
}

async loginPersonell(email: string, password: string, ptenantid: string) {

this.auth.tenantId = ptenantid;


  console.log('login personell', email, password, 'tenant', this.auth.tenantId)

  try {
    const user = await signInWithEmailAndPassword(this.auth, email, password);
    return user;
  } catch (e: any) {
    console.log('login error', e.message)
    return e.message;
  }
}


async passwordReset(email: string, actionCodeSettings?: any) {
//todo: actionCodeSettings
/*
  actionCodeSettings = {
    url: 'https://www.appevent.app/finishpwreset/'+email,
    iOS: {
       bundleId: 'no.appevent.app'
    },
    android: {
      packageName: 'no.appevent.app',
      installApp: true,
      minimumVersion: '12'
    },
    handleCodeInApp: true
  };
*/

    try {
      await sendPasswordResetEmail(this.auth, email, actionCodeSettings);
      return true;
    } catch (e: any) {
      console.log('passwordReset error', e.message)
      return e.message;
    }
}


  logout() {

this.storage.clear();

    return signOut(this.auth);
  }

  async getUserClaims(): Promise<any> {
    const user: User | null = this.auth.currentUser;
    if (user !== null) {
      const idTokenResult = await user.getIdTokenResult();
      return idTokenResult.claims;
    } else {
      console.log('getUserClaims user not logged in')
      return null;
    }
  }
}


